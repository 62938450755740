import React from 'react';
import { Link } from "react-router-dom";
import intl from 'react-intl-universal';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import Image from '../elements/Image';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <h3 className="mt-0 mb-12">
                  UTStamp
                </h3>
                <p className="m-0">
                  {intl.get('UTStamp_intro')}
                </p>
                <div className="reveal-from-bottom btn-space" data-reveal-delay="600">
                  <ButtonGroup>
                    <Button tag="a" color="primary" wideMobile>
                      <Link to="/UtStamp">{intl.get('read_more')}</Link>
                    </Button>
                    <Button tag="a" color="dark" wideMobile href="https://www.utstamp.com/">
                      {intl.get('view_product')}
                    </Button>
                  </ButtonGroup>
                </div>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/product-UTStamp.png')}
                  alt="UTStamp"
                  width={320}
                  height={320} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <h3 className="mt-0 mb-12">
                  Anylocal
                </h3>
                <p className="m-0">
                  {intl.get('Anylocal_slogan')}
                </p>
                <div className="reveal-from-bottom btn-space" data-reveal-delay="600">
                  <Button tag="a" color="dark" wideMobile href="https://www.dev.anylocal.com/">
                    {intl.get('view_anylocal')}
                  </Button>
                </div>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/product-Anylocal.png')}
                  alt="Anylocal"
                  width={498}
                  height={353} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <h3 className="mt-0 mb-12">
                  DigitalCredit
                </h3>
                <p className="m-0">
                  {intl.get('DigitalCredit_slogan')}
                </p>
                <div className="reveal-from-bottom btn-space" data-reveal-delay="600">
                  <Button tag="a" color="dark" wideMobile href="http://digitalcredit.dev.utstamp.com/">
                    {intl.get('view_product')}
                  </Button>
                </div>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/product-DigitalCredit.png')}
                  alt="DigitalCredit"
                  width={360}
                  height={360} />
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;