import React from 'react';
import intl from 'react-intl-universal';
import classNames from 'classnames';
import Image from '../elements/Image';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const UtstampFeatures = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Some of the advanced features of UTStamp',
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>

            <div className="tiles-item" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="">
                  <div className="center-content">
                    <Image
                      src={require('./../../assets/images/ico-loupe.svg')}
                      alt="Auditing"
                      width={48}
                      height={48} />
                  </div>
                  <h6 className="title-feature">{intl.get('auditing')}</h6>
                  <p className="mb-0 text-14">
                    {intl.get('auditing_content')}
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item">
              <div className="tiles-item-inner">
                <div className="">
                  <div className="center-content">
                    <Image
                      src={require('./../../assets/images/ico-shield.svg')}
                      alt="Security"
                      width={48}
                      height={48} />
                  </div>
                  <h6 className="title-feature">{intl.get('security')}</h6>
                  <p className="mb-0 text-14">
                  {intl.get('security_content')}
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="">
                  <div className="center-content">
                    <Image
                      src={require('./../../assets/images/ico-database.svg')}
                      alt="database system"
                      width={48}
                      height={48} />
                  </div>
                  <h6 className="title-feature">{intl.get('database')}</h6>
                  <p className="mb-0 text-14">
                    {intl.get('database_content')}
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

UtstampFeatures.propTypes = propTypes;
UtstampFeatures.defaultProps = defaultProps;

export default UtstampFeatures;