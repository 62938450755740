import React from 'react';
import intl from 'react-intl-universal';
import { Link } from "react-router-dom";
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import UtStamp from '../../views/UtStamp';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const UtstampInfo = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'info-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'UTStamp',
    paragraph: 'Blockchain Plug and Play'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container mt-32">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content has-top-divider pt-24" />
          <div className={splitClasses}>

            <div className="info-item">
              <div className="info-item-content center-content-mobile" data-reveal-container=".info-item">
                <p className="m-0 text-14">
                  {intl.get('UTStamp_info')}
                </p>
                <ul className="text-14">
                  <li>{intl.get('UTStamp_list')}</li>
                  <li>{intl.get('UTStamp_list2')}</li>
                  <li>
                    {intl.get('UTStamp_list3')}
                  </li>
                  <li>{intl.get('UTStamp_list4')}</li>
                  <li>
                    {intl.get('UTStamp_list5')}
                  </li>
                  <li>
                    {intl.get('UTStamp_list6')}
                  </li>
                  <li>
                    {intl.get('UTStamp_list7')}
                  </li>
                </ul>
                <p className="m-0 text-14">
                {intl.get('UTStamp_info2')}
                </p>
              </div>
              <div className={
                classNames(
                  'info-item-image center-content-mobile',
                  imageFill && 'info-item-image-fill'
                )}
                data-reveal-container=".info-item">
                <Image
                  src={require('./../../assets/images/network.svg')}
                  alt="network"
                  width={240}
                  height={240} />
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

UtstampInfo.propTypes = propTypes;
UtstampInfo.defaultProps = defaultProps;

export default UtstampInfo;