import React from 'react';
// import sections
import Header from '../components/layout/Header';
import UtstampInfo from '../components/sections/UtstampInfo';
import UtstampFeatures from '../components/sections/UtstampFeatures';
import Footer from '../components/layout/Footer';

const UtStamp = () => {

  return (
    <>
      <Header />
      <UtstampInfo invertMobile topDivider imageFill />
      <UtstampFeatures topDivider />
      <Footer />
    </>
  );
}

export default UtStamp;